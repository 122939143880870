<ng-container *ngIf="continueToReview">
  <div class="mt-16 flex-row flex-jc-none" *ngrxLet="routeData$ as routeData">
    <button
      mat-icon-button
      class="back-btn"
      [attr.aria-label]="'ARIA_LABEL.BACK' | translate"
      id="header_back-btn"
      [routerLink]="routeData.backUrl"
    >
      <mat-icon>arrow_back_outlined</mat-icon>
    </button>
    <h1 id="header-title">{{ routeData.title | translate }}</h1>
  </div>
</ng-container>
  
<div class="content_main-container">
  <ng-container *ngrxLet="routeData$ as routeData">
    <h1 class="main-title mt-32" id="user-portal_view-address-title">
      {{ (routeData.editPage ? 'COMMON.EDIT_ADDRESS.CHANGE_ADDRESS' : 'COMMON.EDIT_ADDRESS.ADD_ADDRESS') | translate }}
    </h1>
  </ng-container>
  <ng-container *ngrxLet="defaultShippingAddressRef$ as defaultShippingAddressRef">
    <pbb-address-edit
      [address]="address"
      (submit$)="saveShippingAddress($event)"
      [isDefaultAddress]="address && defaultShippingAddressRef === address.shipping_address_ref"
      [isLoading]="isLoading"
    ></pbb-address-edit>
  </ng-container>
</div>
